<template>
    <div>
        <!-- CAROUSEL-->
    <b-row>
      <b-col cols="12" >
        <div>
          <b-carousel indicators :interval="3000" controls >
            <b-carousel-slide     
                v-if="resources!=null"           
                :img-src="resources.img_header_principal"
            />
          </b-carousel>
        </div>
      </b-col>
    </b-row>

        <br>
        <!-- <h1 class="titles">
          
          <span style="color: #d82125">G</span>
          <span style="color: #d02175">o</span>
          <span style="color: #f6c921">b</span>
          <span style="color: #d02175">i</span>
          <span style="color: #4d944a">e</span>
          <span style="color: #07aaba">r</span>
          <span style="color: #d82125">n</span>
          <span style="color: #d02175">o</span>
          
        </h1> -->

        <h1 class="titles mt-3">
          <span class="c-shadow-sm" style="color: #8b8c8c; padding:15px;">Gobierno</span>
        </h1>


    <b-row align-h="center" v-if="resources!=null"> 
        <b-col cols="10" sm="8" md="6" lg="4" class="mt-5 mb-5" >
          <b-container>
            <b-img :src="resources.img_presidente" fluid rounded class="c-shadow-sm" ></b-img>
          </b-container>
          <br>
          <div class="mt-5">
            <h4><span><b>{{resources.nombre_presidente}}</b></span></h4>
            <h5><span>{{resources.cargo_presidente}}</span></h5>
          </div>
        </b-col>
        
          
        
    </b-row>
    <b-row  align-h="center" class="mt-4" v-if="resources!=null">
      <b-col cols="10" sm="10" md="9" lg="7"  class="mb-5">
          <b-container>
            <b-img :src="resources.img_gabinete" fluid rounded class="c-shadow-sm" ></b-img>
          </b-container>
          <br>
          <div class="mt-5">
            <div v-for="(persona, i) in resources.gabinete" :key="i" class="mt-3">
              <h4><span><b>{{persona.nombre}}</b></span></h4>
              <h5><span>{{persona.cargo}}</span></h5>
            </div>            
          </div>
        </b-col>
    </b-row>

    </div>
</template>
<script>
  import axios from "axios";
  export default {
    components: {
    },
    mounted: function(){
      var host = location.protocol + '//' + location.host;
      axios({
          method: 'get',
          url: host+'/files/gobierno.json',
      })
      .then(res => {
          this.resources = res.data;
      }).catch(err => {
        alert(err);  
      });
    },
    computed: {
      
    },
    data() {
      return {
        resources:null,
      }
    }
    
  }
</script>